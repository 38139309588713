// React imports

// Remix imports
import { Outlet } from '@remix-run/react'
import type { ActionFunction } from '@remix-run/server-runtime'

// Third party imports

// Generated imports
import type { NewsletterSignupViewMutation } from '~/generated/graphql'

// App imports
import { getLang } from '~/i18n.universal'
import {
  mutationName,
  NEWSLETTER_SIGNUP_MUTATION,
} from '~/routes/__buyer_layout/$lang/newsletter.signup'
import actionFormSubmit from '~/utils/actionFormSubmit.server'
import getFormData from '~/utils/getFormData'
import { actionJson } from '~/utils/jsonResponse.server'
import redirect303 from '~/utils/redirect303.server'
import urlReverse from '~/utils/urlReverse'

// ============================================================================
// HTML =======================================================================
// ============================================================================
export default function LangActionRoute() {
  return <Outlet />
}

// ============================================================================
// ACTION =====================================================================
// ============================================================================
export const action: ActionFunction = async ({ request, params }) => {
  const lang = getLang(params)
  const formDataObj = await getFormData(request)

  const { data, formErrors, headers } =
    await actionFormSubmit<NewsletterSignupViewMutation>(
      request,
      params,
      formDataObj,
      NEWSLETTER_SIGNUP_MUTATION,
      mutationName
    )

  if (data.newNewsletterSignup?.status === 200) {
    return redirect303(urlReverse('newsletterSuccess', { lang }), headers)
  }
  return actionJson({ formErrors }, { headers })
}
